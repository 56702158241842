<!-- =========================================================================================
  File Name: ModuleView.vue
  Description: Module View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/module/pixinvent
========================================================================================== -->

<template>
  <div id="page-module-view">

    <vs-alert :active.sync="module_not_found" color="danger" title="Module Not Found">
      <span>Module record with id: {{ $route.params.moduleId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link :to="{name:'page-module-list'}" class="text-inherit underline">All Modules</router-link>
      </span>
    </vs-alert>


    <div v-if="module_data" id="module-data">
      <div class="vx-row">

        <div class="vx-col lg:w-1/3 w-full">
          <vx-card class="mb-base" style="height:95%" title="Module Actions">
            <!-- Avatar -->
            <div class="vx-row">

              <div class="vx-col w-full flex">
                <vs-button @click="activePrompt = true" class="w-full" color="success" icon="icon-book" icon-pack="feather" style="height:2rem;">
                  Assign Course
                </vs-button>
              </div>

              <div class="vx-col w-full flex pt-3">
                <vs-button @click="updateStatistics" class="w-full" color="primary" icon="icon-refresh-cw" icon-pack="feather" style="height:2rem;">
                  Update Stats
                </vs-button>
              </div>

              <div class="vx-col w-full flex pt-3">
                <vs-button :to="`/a/modules/${$route.params.moduleId}/edit`" class="w-full" color="warning" icon="icon-edit" icon-pack="feather" style="height:2rem;">
                  Edit
                </vs-button>
              </div>

              <!-- /Information - Col 2 -->
              <div class="vx-col w-full flex pt-3">
                <vs-button class="w-full" color="danger" icon="icon-archive" icon-pack="feather" style="height:2rem;">
                  Delete
                </vs-button>
              </div>

            </div>
          </vx-card>
        </div>

        <div class="vx-col lg:w-2/3 w-full">
          <vx-card class="mb-base" style="height:95%" title="Extended Information">
            <table>
              <tr>
                <td class="font-semibold">Module Name</td>
                <td>{{ module_data.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Module Duration</td>
                <td>{{ module_data.statistics.total_seconds | duration }}</td>
              </tr>

              <tr>
                <td class="font-semibold">Module Lessons</td>
                <td>{{ module_data.lesson_ids.length }}</td>
              </tr>

              <tr>
                <td class="font-semibold">Module Form URL</td>
                <td><a :href="module_data.end_of_module_iframe_url">Link</a></td>
              </tr>
            </table>
          </vx-card>
        </div>


      </div>


    </div>

    <vs-prompt
      :active.sync="activePrompt"
      accept-text="Submit Module"
      title="Add this Module to a Course"
      @accept="submitCourseAdd"
      @cancel="course_id=''">
      <div>
        <span>Enter the Course ID</span>
        <vs-input v-model="course_id" class="mt-3 w-full" placeholder="Course ID" vs-placeholder="Course ID"/>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      module_data: null,
      module_not_found: false,
      attributes: {},

      activePrompt: false,
      course_id: '',
    };
  },
  methods: {
    updateStatistics() {

      this.$vs.loading();

      this.$http.post(`modules/${this.module_data.id}/statistics`)
        .then(response => {

          if (response.data) {
            this.$vs.loading.close();

            setTimeout(() => location.reload(), 2500)

            return this.$vs.notify({
              color: 'success',
              title: 'Refresh Module Statistics',
              text: 'Successfully updated the statistics for this module'
            })

          }

          throw new Error({response})
        })
        .catch(exception => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while loading the metadata of this course';
          if (exception.response) {
            error = exception.response.data.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to load lesson author',
            text: error,
            color: 'danger',
          });

        });

    },
    submitCourseAdd() {

      this.$vs.loading();

      this.$http.put(`courses/${this.course_id}/modules/${this.module_data.id}`)
        .then(response => {

          if (response.data.data) {
            this.$vs.loading.close();

            return this.$vs.dialog({
              color: 'success',
              title: `Added Module to Course`,
              text: 'Successfully added this module to the specified course',
            });

          }

          throw new Error({
            response: response
          })

        })
        .catch(exception => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while adding the module';

          if (exception.response) {
            if (exception.response.data.error) {
              error = exception.response.data.error.description;
            }
          }

          return this.$vs.notify({
            title: 'Failed to add module',
            text: error,
            color: 'danger',
          });

        });

    },

    loadMeta() {

    },
    showError(exception) {

      let error = 'An unknown error occurred while modifying this module';
      if (exception.response) {
        error = exception.response.data.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to update module',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
  created() {

    const moduleId = this.$route.params.moduleId;

    this.$http.get(`modules/${moduleId}`)
      .then(response => {

        if (response.data.data) {
          this.module_data = response.data.data;
        }

      })
      .catch(error => {
        console.error(error);

        if (error.response && error.response.status === 404) {
          this.module_not_found = true;
          return;
        }
      });
  },
};

</script>

<style lang="scss">
#page-module-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}
</style>
